.wines-filter-dropdown{
    text-align: center;
    border-radius: 6px;
    background-color: #669;

    .btn{
        border: 0px solid transparent;
        color: #fff;
    }

    .btn::after{
        display: none;
    }

    .btn:focus{
        box-shadow: none !important;
    }

    .dropdown-menu{
        transform: translate(0px, 39px) !important;
        text-align: center;
        width: 100%;
    }
}

.wines-banner{
    background-image: url('/assets/images/wines/Our_Wines_Page-3.jpg');
}