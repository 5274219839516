.searchBox {
	border: none;
    width: 0px !important;
  }

  .multiSelectContainer input{
    margin-top: 0px !important;
  }

.searchWrapper {
    padding: 5px !important;
}

.option { 
	color: black !important;
  }

.highlightOption{
    background-color: white !important
}

.multiSelectContainer li{
    text-align: center;
}
.multiSelectContainer li:hover{
    background: lightgray !important;
    text-align: center;
}

.multiSelectContainer li {
    padding: 5px !important;
}

.chip {
    padding: 4px 10px !important;
    background: #f5f5f5 !important;
    margin-right: 5px !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    color: #000000d9 !important;
    border-radius: 0px !important;
}

.chip .custom-close{
    margin-left: 4px;
    cursor: pointer;
}

.clear-filters{
    text-decoration: underline;
}

.header-area a {
    color: #669 !important
}

.wine-item .title {
    font-weight: 500;
    font-size: 36px;
}

.wine-item .manufacturer-name{
    font-weight: 300;
}

.wine-item .nav-tabs{
    border-bottom: none;
}

.wine-item .nav-tabs .nav-item.show .nav-link, .wine-item .nav-tabs .nav-link.active{
    border-radius: 1.25rem;
    color: white;
    background-color: #669;
}

.wine-item .nav-tabs .nav-link{
    border-radius: 1.25rem;
}

.wine-item .tab-container{
    padding-top: 3%;
    padding-left: 1%;
}

.wine-item .tab-container tr:first-child td {  
    border-top: none;
}

.contact-us-banner{
    background-image: url(/assets/images/contact/contact_us.jpg) !important;
}